import Button from 'components/Button';
import { ButtonProps } from 'components/Button/types';
import Panel from 'components/Panel';
import Tour from 'customer/components/Layout/Tour/Welcome';
import {
  getInviteCode,
  getInviteCodeUnlocked,
} from 'customer/components/iam/InviteCode/service';
import useLogin from 'customer/components/iam/Login/hooks';
import useKycProfile from 'customer/components/kyc/Profile/hooks';
import { Profile } from 'customer/components/kyc/Profile/types';
import { isDraft } from 'customer/components/kyc/Profile/util';
import userActions from 'customer/components/kyc/User/actions';
import { User } from 'customer/components/kyc/User/types';
import { needsInfo } from 'customer/components/kyc/User/util';
import { useTreasuryAccountList } from 'customer/components/treasury/Account/hooks';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { CurrencyCode } from 'types/emoney/Token';
import history from 'utils/history';

import { useConnected } from 'components/Wallet/hooks';
import { useAppContext } from 'customer/context';
import Unlocking from '../../iam/InviteCode/Unlocking';
import useProfile from '../../iam/Profile/hooks';
import s from './Account.less';
import CurrencyGroup from './Group/Group';
import LinkAccount from './Link';
import { useSettingsContext } from './Settings/SettingsProvider';
import { useAccountList } from './hooks';
import type { AccountByCurrency } from './types';
import {
  mapAccountsToCurrency,
  sortAccounts,
  sumBalancesByCurrency,
} from './util';

const AddButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { icon?: string }
>(({ children, icon, ...props }, forwardedRef) => {
  useStyles(s);

  return (
    <button
      ref={forwardedRef}
      className={s.walletButton}
      type="button"
      {...props}
    >
      <span className="material-icons">{icon}</span>
      {children && <span>{children}</span>}
    </button>
  );
});

const AnimatedPanel = animated(Panel);
const Accounts = () => {
  useStyles(s);
  const dispatch = useDispatch();
  const { profile } = useKycProfile();
  const { treasuryAccounts } = useTreasuryAccountList();
  const {
    accounts,
    isSuccess: isAccountListSuccess,
    isFetching: isAccountListFetching,
  } = useAccountList();

  const { address: connectedAddressCosmos, chain: connectedChainCosmos } =
    useConnected('cosmos');
  const { address: connectedAddressEvm, chain: connectedChainEvm } =
    useConnected('evm');

  const selectedAddress = connectedAddressEvm || connectedAddressCosmos;
  const selectedChain = connectedChainEvm || connectedChainCosmos;

  const { isFeatureFixChainNames, isFeatureProfileV2Customer } =
    useAppContext();

  const { profileId } = useProfile();
  const [accountsByCurrency, setAccountsByCurrency] = useState<
    AccountByCurrency[]
  >([]);
  const { settingsView, setSettingsView } = useSettingsContext();
  const [inviteCode, setInviteCode] = useState<string>();
  const [sumByCurrency, setSumByCurrency] =
    useState<Record<CurrencyCode, number>>();
  const [unlockCodeOpen, setUnlockCodeOpen] = useState(false);
  const [newAccount, setNewAccount] = useState<
    { address: string; chain?: string } | undefined
  >(undefined);

  useEffect(() => {
    if (newAccount?.address) {
      const exists = accounts?.find(
        (acc) =>
          acc.address === newAccount?.address &&
          acc.chain === newAccount?.chain,
      );
      if (exists) {
        history?.push(`/addresses/${newAccount?.address}/${newAccount?.chain}`);
      }
    }
  }, [newAccount?.address, accounts]);

  const { isInvited } = useLogin();

  const transitions = useTransition(settingsView, {
    from: { maxHeight: '0px', opacity: 0 },
    enter: { maxHeight: '500px', opacity: 1 },
    leave: { maxHeight: '0px', opacity: 0 },
  });

  /**
   * Scanning the user that logged in and if he's identified as an
   * additional user (missing user information), he's redirected
   * to his "My Profile" view in order to supply the missing info.
   */
  const redirectIfAdditionalUser = async (kycProfile: Profile) => {
    try {
      const currentUser = await dispatch(userActions.read());
      if (
        kycProfile?.kind === 'corporate' &&
        !isDraft(profile) &&
        currentUser &&
        needsInfo(currentUser as User) &&
        history
      ) {
        history.push('/profiles');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (profile?.id && !isFeatureProfileV2Customer) {
      // all of this additional user code will be removed
      // after CorpProfileV2 is implemented
      redirectIfAdditionalUser(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (!isAccountListSuccess || !accounts?.length) return;

    const byCurrency = mapAccountsToCurrency(treasuryAccounts, accounts);

    // skip sorting in settings view in order not to confuse
    // customer when he's selecting which accounts to show
    if (!settingsView) {
      byCurrency.forEach((group) => {
        group.accounts.sort((a, b) =>
          sortAccounts(
            a,
            b,
            selectedAddress,
            isFeatureFixChainNames ? selectedChain?.id : selectedChain?.chain,
            treasuryAccounts,
          ),
        );
      });
      setAccountsByCurrency(byCurrency);
      const sums = sumBalancesByCurrency(accounts);
      setSumByCurrency(sums);
    }
  }, [
    isAccountListFetching,
    isAccountListSuccess,
    accounts,
    treasuryAccounts,
    settingsView,
    selectedAddress,
    selectedChain,
  ]);

  useEffect(() => {
    const iCode = getInviteCode();
    // Checking CodeUnlocked is to make sure users onboarded with earlier
    // version of the codes won't be put through the new flow
    const iCodeUnlocked = getInviteCodeUnlocked();
    if (iCode && !iCodeUnlocked && !isInvited) {
      setInviteCode(iCode);
      setUnlockCodeOpen(true);
    }
  }, []);
  return (
    <>
      {inviteCode && unlockCodeOpen ? (
        <Unlocking
          profileId={profileId}
          inviteCode={inviteCode}
          close={() => setUnlockCodeOpen(false)}
        />
      ) : (
        <>
          {!!accounts?.length && (
            <div className={s.header}>
              <span className={s.title}>Wallets</span>
              <span className={s.settings}>
                <>
                  <LinkAccount
                    trigger={
                      <AddButton icon="add" title="Add a wallet">
                        Add
                      </AddButton>
                    }
                    onSuccess={(address, chain) => {
                      setNewAccount({ address, chain });
                    }}
                  />
                  {!settingsView ? (
                    <Button
                      onClick={() => setSettingsView(!settingsView)}
                      small
                      icon={'settings'}
                    >
                      Hide accounts
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setSettingsView(!settingsView)}
                      small
                      icon={'close'}
                    >
                      Back to account overview
                    </Button>
                  )}
                </>
              </span>
            </div>
          )}
          {settingsView
            ? transitions(
                (styles, item) =>
                  item && (
                    <div className={s.header}>
                      <AnimatedPanel style={styles} warning>
                        Note! Smart contract wallets like Safe are usually only
                        deployed to one chain, like Gnosis. Ensure only to
                        enable/show the chains to which the wallet supports. You
                        can <strong>lose funds </strong>
                        when receiving funds to an address on a chain you do not
                        control.
                      </AnimatedPanel>
                    </div>
                  ),
              )
            : null}
          <>{isAccountListSuccess && !accounts?.length && <Tour />}</>

          {accountsByCurrency.map((acc) => (
            <CurrencyGroup
              key={`accounts-currency-${acc.currency}`}
              accountsByCurrency={acc}
              sumByCurrency={sumByCurrency}
            />
          ))}
        </>
      )}
    </>
  );
};

export default Accounts;
