import {
  ReduxStore,
  useAppDispatch,
  useAppSelector,
} from 'customer/store/configureStore';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { PersonKind, RoleArray } from 'types/kyc/Person';
import {
  CorporateDetails,
  CorporateForm,
  CorporateProfile,
  PersonalForm,
  PersonalProfile,
  Profile,
  ProfileKind,
  ProfileV2,
} from 'types/kyc/Profile';
import { NewVerification, Verification } from 'types/kyc/Verification';
import actions from './actions';

interface UseKycProfile {
  profile: PersonalProfile | CorporateProfile | undefined;
  isReadSuccess: boolean;
  create: (kind: ProfileKind, profileId: string) => Promise<ProfileV2 | void>;
  read: (profileId: string) => Promise<Profile | void>;
  readV2: (profileId: string) => Promise<ProfileV2 | void>;
  reset: (profileId: string, data: Partial<Profile>) => void;
  store: (profileId: string, data: Partial<Profile>) => Promise<Profile | void>;
  storeV2: (
    profileId: string,
    data: Partial<Profile>,
  ) => Promise<Profile | void>;
  storeDetails: (
    profileId: string,
    data: Partial<CorporateDetails>,
  ) => Promise<ProfileV2 | void>;
  storeForm: (
    profileId: string,
    kind: ProfileKind,
    data: Partial<CorporateForm | PersonalForm>,
  ) => Promise<ProfileV2 | void>;
  storeRoles: (profileId: string, data: RoleArray) => Promise<ProfileV2 | void>;
  updateOwnership: (
    profileId: string,
    personId: string,
    ownershipPercentage: number,
  ) => Promise<ProfileV2 | void>;
  removeRole: (
    profileId: string,
    personId: string,
    role: PersonKind,
  ) => Promise<Response | void>;
  createVerifications: (
    profileId: string,
    data: NewVerification[],
  ) => Promise<ProfileV2 | void>;
  updateVerification: (
    profileId: string,
    verification: Verification,
  ) => Promise<ProfileV2 | void>;
  removePerson: (profileId: string, personId: string) => void;
  versions: (profileId: string) => Promise<Profile[] | void>;
}

const selectProfile = () =>
  useAppSelector((state: ReduxStore) => state.kyc.profile.detail, shallowEqual);

const selectIsReadSuccess = () =>
  useAppSelector(
    (state: ReduxStore) => !!state.kyc.profile.isReadSuccess,
    shallowEqual,
  );

const useKycProfile = (): UseKycProfile => {
  const dispatch = useAppDispatch();

  return {
    profile: selectProfile(),
    isReadSuccess: selectIsReadSuccess(),
    create: useCallback(
      (kind: ProfileKind, profileId: string) =>
        dispatch(actions.create(kind, profileId)).catch(console.error),
      [dispatch],
    ),
    read: useCallback(
      (profileId: string) =>
        dispatch(actions.read(profileId)).catch(console.error),
      [dispatch],
    ),
    readV2: useCallback(
      (profileId: string) => dispatch(actions.readV2(profileId)),
      [dispatch],
    ),
    reset: useCallback(
      (profileId: string, data: Partial<Profile>) =>
        dispatch(actions.reset(profileId, data)),
      [dispatch],
    ),
    store: useCallback(
      (profileId: string, data: Partial<Profile>) =>
        dispatch(actions.store(profileId, data)).catch(console.error),
      [dispatch],
    ),
    storeV2: useCallback(
      (profileId: string, data: Partial<ProfileV2>) =>
        dispatch(actions.storeV2(profileId, data)).catch(console.error),
      [dispatch],
    ),
    storeDetails: useCallback(
      (profileId: string, data: Partial<CorporateDetails>) =>
        dispatch(actions.storeDetails(profileId, data)).catch(console.error),
      [dispatch],
    ),
    storeForm: useCallback(
      (
        profileId: string,
        kind: ProfileKind,
        data: Partial<CorporateForm | PersonalForm>,
      ) =>
        dispatch(actions.storeForm(profileId, kind, data)).catch(console.error),
      [dispatch],
    ),
    storeRoles: useCallback(
      (profileId: string, data: RoleArray) =>
        dispatch(actions.storeRoles(profileId, data)).catch(console.error),
      [dispatch],
    ),
    updateOwnership: useCallback(
      (profileId: string, personId: string, ownershipPercentage: number) =>
        dispatch(
          actions.updateOwnership(profileId, personId, ownershipPercentage),
        ).catch(console.error),
      [dispatch],
    ),
    removeRole: useCallback(
      (profileId: string, personId: string, role: PersonKind) =>
        dispatch(actions.removeRole(profileId, personId, role)).catch(
          console.error,
        ),
      [dispatch],
    ),
    createVerifications: useCallback(
      (profileId: string, data: NewVerification[]) =>
        dispatch(actions.createVerifications(profileId, data)).catch(
          console.error,
        ),
      [dispatch],
    ),
    updateVerification: useCallback(
      (profileId: string, verification: Verification) =>
        dispatch(actions.updateVerification(profileId, verification)).catch(
          console.error,
        ),
      [dispatch],
    ),
    removePerson: useCallback(
      (profileId: string, personId: string) =>
        dispatch(actions.removePerson(profileId, personId)).catch(
          console.error,
        ),
      [dispatch],
    ),
    versions: useCallback(
      (profileId: string) =>
        dispatch(actions.versions(profileId)).catch(console.error),
      [dispatch],
    ),
  };
};

export default useKycProfile;
